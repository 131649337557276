<template>
  <div>
    <defaultFieldTypes
    v-if="valueLine.type=='teststatus'"
     :fieldAttributes="fieldAttributes"
      :field="field"
      :templateContent="result"
      v-on="$listeners"
      :checkReadOnly="checkReadOnly"
      :value="value"
      :valueLine="valueLine"
      :hideDetails="hideDetails"
    ></defaultFieldTypes>

  </div>
</template>
<script>
import defaultFieldTypes from "@/commonComponents/defaultFieldTypes.vue";

export default {
  props: ["result", "fieldAttributes", "field","value","checkReadOnly","valueLine","hideDetails"],
  components: { defaultFieldTypes },
 
}
</script>